import React, { useState, useEffect, useRef } from "react";
import styles from "./ChatBot.module.css";
import {
  auth,
  db,
  collection,
  getDocs,
  onAuthStateChanged,
  doc,
  // updateDoc,
  // arrayUnion,
  getDoc,
} from "../firebase-config";
import DisplayChatbotProfile from "../pages/students/p3/DisplayChatbotProfile";

export default function ChatBot() {
  const [students, setStudents] = useState([]);
  const [currentStudentIndex, setCurrentStudentIndex] = useState(0);
  const [messages, setMessages] = useState([]);
  const [inputValue, setInputValue] = useState("");
  const [isTyping, setIsTyping] = useState(false);
  const [displayProfile, setDisplayProfile] = useState(false);
  const [isLoading, setIsLoading] = useState(true); // Loading state
  const chatDisplayRef = useRef(null); // Reference for auto-scrolling
  const [selectedAStudent, setSelectedAStudent] = useState(false);

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, async (user) => {
      if (user) {
        try {
          const userDocRef = doc(db, "Students", user.uid);
          const userDocSnap = await getDoc(userDocRef);
          let syncedUsersArray = [];
          if (userDocSnap.exists() && userDocSnap.data().syncedUsers) {
            syncedUsersArray = userDocSnap.data().syncedUsers;
          }

          const querySnapshot = await getDocs(collection(db, "Students"));
          const allStudents = [];

          querySnapshot.forEach((doc) => {
            const studentData = doc.data();

            // Check if any of the required fields are missing
            if (
              doc.id !== user.uid &&
              !syncedUsersArray.includes(doc.id) &&
              studentData.fullName &&
              studentData.age &&
              studentData.gender &&
              studentData.headshot &&
              studentData.school &&
              studentData.major &&
              studentData.clubs &&
              studentData.schoolEmail &&
              studentData.resumeData
            ) {
              allStudents.push({
                uid: doc.id,
                fullName: studentData.fullName,
                age: studentData.age,
                gender: studentData.gender,
                picture: studentData.headshot,
                school: studentData.school,
                major: studentData.major,
                clubs: studentData.clubs,
                email: studentData.schoolEmail,
                resumeData: studentData.resumeData,
              });
            }
          });

          if (allStudents.length > 0) {
            // Set the initial messages for the first student
            setMessages([
              {
                text: `Hello, my name is ${allStudents[0].fullName}.`,
                sender: "bot",
              },
            ]);
          }
          setStudents(allStudents);
          setIsLoading(false); // Stop loading
        } catch (error) {
          console.error("Error fetching student data: ", error);
          setIsLoading(false); // Stop loading if error occurs
        }
      }
    });

    return () => unsubscribe();
  }, []);

  useEffect(() => {
    // Auto-scroll to the bottom when new messages are added
    if (chatDisplayRef.current) {
      chatDisplayRef.current.scrollTop = chatDisplayRef.current.scrollHeight;
    }
  }, [messages]);

  // const handleSkip = () => {
  //   if (students.length > 1) {
  //     setCurrentStudentIndex((prevIndex) => {
  //       const nextIndex = (prevIndex + 1) % students.length;
  //       // Reset messages with the next student's name
  //       setMessages([
  //         {
  //           text: `Hello, my name is ${students[nextIndex].fullName}.`,
  //           sender: "bot",
  //         },
  //       ]);
  //       return nextIndex;
  //     });
  //   } else {
  //     setStudents([]); // No more students left to review
  //     setMessages([]); // Clear messages if no students are left
  //   }
  // };

  // const handleSync = async () => {
  //   const currentStudent = students[currentStudentIndex];
  //   try {
  //     const user = auth.currentUser;
  //     if (user) {
  //       const userDocRef = doc(db, "Startups", user.uid);

  //       await updateDoc(userDocRef, {
  //         syncedUsers: arrayUnion(currentStudent.uid),
  //       });

  //       setStudents((prevStudents) =>
  //         prevStudents.filter((student) => student.uid !== currentStudent.uid)
  //       );
  //       setCurrentStudentIndex(0);

  //       // Reset messages for the next student
  //       if (students.length > 1) {
  //         setMessages([
  //           {
  //             text: `Hello, my name is ${students[0].fullName}.`,
  //             sender: "bot",
  //           },
  //         ]);
  //       } else {
  //         setMessages([]);
  //       }

  //       console.log(`Successfully synced with ${currentStudent.fullName}.`);
  //     }
  //   } catch (error) {
  //     console.error("Error syncing user: ", error);
  //   }
  // };

  if (isLoading) {
    return (
      <div className={styles.loadingContainer}>
        <div className={styles.spinner}></div>
      </div>
    );
  }

  if (students.length === 0) {
    return <div className={styles.noStudentMessage}>No students available</div>;
  }

  const currentStudent = students[currentStudentIndex];

  const handleSubmit = () => {
    if (inputValue.trim()) {
      // Add the user's message to the messages state
      const newMessage = { text: inputValue, sender: "user" };
      setMessages((prevMessages) => [...prevMessages, newMessage]);
      setInputValue(""); // Clear the input field after sending the message
      // Handle bot response (this can be connected to an API or simple logic)
      handleBotResponse(inputValue);
    }
  };

  const handleBotResponse = async (userInput) => {
    setIsTyping(true); // Show the typing indicator
    const currentStudent = students[currentStudentIndex];
    const name = currentStudent.fullName;
    const school = currentStudent.school;
    const major = currentStudent.major;
    const clubs = currentStudent.clubs;
    const resumeData = currentStudent.resumeData;

    try {
      const response = await fetch("/api/chat-bot", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          message: userInput,
          name,
          school,
          major,
          clubs,
          resumeData,
        }),
      });

      const data = await response.json();
      const botResponse = data.response;

      const newBotMessage = { text: botResponse, sender: "bot" };
      setMessages((prevMessages) => [...prevMessages, newBotMessage]);
    } catch (error) {
      const errorMessage = {
        text: "Oops, something went wrong!",
        sender: "bot",
      };
      setMessages((prevMessages) => [...prevMessages, errorMessage]);
    } finally {
      setIsTyping(false); // Hide the typing indicator
    }
  };

  const handleBotContactResponse = () => {
    setIsTyping(true);
    const currentStudent = students[currentStudentIndex];
    const email = currentStudent.schoolEmail;

    try {
      const response = `${email ? email : 'Sorry, try again later'}.`
      const newBotMessage = { text: response, sender: "bot" };
      setMessages((prevMessages) => [...prevMessages, newBotMessage]);
    // Error Handling
    } catch (error) {
      const errorMessage = {
        text: "Oops, something went wrong!",
        sender: "bot",
      };
      setMessages((prevMessages) => [...prevMessages, errorMessage]);
    
    } finally {
      setIsTyping(false);
    }
  }

  const getFirstName = () => {
    const studentName = currentStudent.fullName.split(" ")[0];
    return studentName.endsWith("s") ? `${studentName}'` : `${studentName}'s`;
  };

  const toggleStudentProfile = () => {
    setDisplayProfile(!displayProfile);
  };

  const handleStudentClick = (index) => {
    setSelectedAStudent(true);
    setCurrentStudentIndex(index);
    setMessages([
      { text: `Hello, my name is ${students[index].fullName}.`, sender: "bot" },
    ]);
  };  

  const defaultView = () => {
    return (
      <div className={styles.defaultChatbotViewContainer}>
        <h2>Meet students now</h2>
        <h4>
          To begin meeting students on our platform, select any student on the
          sidebar to the left of the screen
        </h4>
        <img src="./images/messages.svg" alt="messages" />
      </div>
    );
  };

  const activeView = () => {
    return (
      <>
      <div onClick={(e) => e.stopPropagation()}>
        {displayProfile && (
          <div className={selectedAStudent ? styles.modalOverlay : ''} onClick={toggleStudentProfile}>
            <div className={styles.modalContent} onClick={(e) => e.stopPropagation()}>
              <img onClick={toggleStudentProfile} className={styles.closeButton} src='./images/close.svg' alt="close-button"/>
              <DisplayChatbotProfile
                name={currentStudent.fullName}
                age={currentStudent.age}
                gender={currentStudent.gender}
                profilePictureURL={currentStudent.picture}
                school={currentStudent.school}
                major={currentStudent.major}
              />
            </div>
          </div>
        )}
        </div>
        {/* idk */}
        <div className={styles.chatDisplayInnerContainer} ref={chatDisplayRef}>
          {messages.map((message, index) => (
            <div className={styles.chatDisplay} key={index}>
              {/* Any messages sent by bot should have a profile picture next to them */}
              {message.sender !== "user" && (
                <img src={currentStudent.picture} alt="" />
              )}
              <div
                className={
                  message.sender === "user"
                    ? styles.userMessage
                    : styles.botMessage
                }
              >
                {message.text}
              </div>
            </div>
          ))}

          {isTyping && (
            <div className={styles.typingIndicator}>Bot is typing...</div>
          )}
        </div>
        {/* User Message Container */}
        <div className={styles.messageInputContainer}>
          <textarea
            className={styles.chatInput}
            placeholder="send message"
            value={inputValue}
            onChange={(e) => setInputValue(e.target.value)}
            onKeyDown={(e) => {
              if (e.key === "Enter" && !e.shiftKey) {
                e.preventDefault();
                handleSubmit();
              }
            }}
          />
          <div className={styles.chatDivider} />
          {/* Message Buttons: Discard, See Profile, Send */}
          <div className={styles.chatButtonContainer}>
            {/* Next Button */}
            <button
              className={styles.nextMessageButton}
              onClick={(e) => handleBotContactResponse()}
            >
              Contact
            </button>
            {/* See Profile Button */}
            <button
              onClick={(e) => toggleStudentProfile()}
              className={styles.seeStudentProfileButton}
            >
              See {getFirstName()} profile
            </button>
            {/* Send Message button */}
            <button className={styles.sendMessageButton}>
              <img
                onClick={(e) => handleSubmit()}
                src="./images/sendMessage.svg"
                alt="send-message"
              />
            </button>
          </div>
        </div>
      </>      
    );
  }

  return (
    <div className={styles.chatbotActiveContainer}>
      {/* Sidebar displaying student names */}
      <nav className={styles.sidebar}>
        {students.map((student, index) => (
          <div onClick={() => handleStudentClick(index)} className={index === currentStudentIndex ? styles.selectedStudent : styles.studentSelection} key={index}>
            <img src={student.picture} alt="" />
            <button
              key={student.uid}
            >
             <p>{student.fullName}</p> 
            </button>
          </div>
        ))}
      </nav>

      <div className={styles.chatbotDisplayContainer}>
        <>{selectedAStudent ? activeView() : defaultView()}</>
      </div>
    </div>
  );
}