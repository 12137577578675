import React, { useState } from "react";
import PropTypes from "prop-types";
import {
  auth,
  createUserWithEmailAndPassword,
  sendEmailVerification,
  db,
  setDoc,
  doc,
} from "../../../firebase-config";
import { GoogleAuthProvider, signInWithPopup } from "firebase/auth";
import googleImg from "../p1/google.png";
import styles from "./SignUpForm.module.css";

const SignUpForm = ({ toggleMode }) => {
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [isStudentEmail, setIsStudentEmail] = useState(false);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");

  const handleEmailChange = (e) => {
    const enteredEmail = e.target.value;
    setEmail(enteredEmail);
    setIsStudentEmail(enteredEmail.endsWith(".edu"));
  };

  const handleSignup = async (e) => {
    e.preventDefault();
    setLoading(true);

    if (!isStudentEmail) {
      setError("Please use a valid .edu email address.");
      setLoading(false);
      return;
    }

    try {
      const userCredential = await createUserWithEmailAndPassword(
        auth,
        email,
        password
      );
      const user = userCredential.user;

      // Store the full name in Firestore (School Email will be stored during login)
      await setDoc(doc(db, "Students", user.uid), {
        fullName: name,
        profileCreated: false,
      });

      await sendEmailVerification(user);

      setError(
        "Verification email sent! Please check your email to verify your account."
      );

      // Automatically switch to the Sign In form after sign-up
      toggleMode();
    } catch (error) {
      setError("Error during signup. Please try again");
    } finally {
      setLoading(false);
    }
  };

  const handleGoogleSignup = async () => {
    setLoading(true);
    try {
      const provider = new GoogleAuthProvider();
      const result = await signInWithPopup(auth, provider);
      const user = result.user;

      // Check if the user's email ends with ".edu"
      if (!user.email.endsWith(".edu")) {
        setError("Students must sign up using .edu email address");
        await auth.signOut();
        setLoading(false);
        return;
      }

      // Automatically switch to the Sign In form after sign-up
      toggleMode();
    } catch (error) {
      setError("Error signing in with Google. Please try again.");
      console.error("Google Sign Up Error:", error);
    } finally {
      setLoading(false);
    }
  };

  return (
    <form className={styles.signUpForm} onSubmit={handleSignup}>
      <div className={styles.nameWrapper}>
        <input
          type="text"
          placeholder="Full Name"
          value={name}
          onChange={(e) => setName(e.target.value)}
          className={styles.input}
          required
          disabled={loading}
        />
      </div>
      <div className={styles.emailWrapper}>
        <input
          type="email"
          placeholder="School Email"
          value={email}
          onChange={handleEmailChange}
          className={`${styles.input} ${!isStudentEmail && email ? styles.invalidInput : ""
            }`}
          required
          disabled={loading}
        />
        {!isStudentEmail && email && (
          <p className={styles.error}>
            Please use a valid .edu email address.
          </p>
        )}
      </div>
      <div className={styles.passwordWrapper}>
        <input
          type="password"
          placeholder="Password"
          value={password}
          onChange={(e) => setPassword(e.target.value)}
          className={styles.input}
          required
          disabled={loading}
        />
      </div>
      {error && <p className={styles.error}>{error}</p>}
      <div className={styles.submissionButton}>
        <button
          type="submit"
          className={styles.done}
          disabled={loading || !isStudentEmail}
        >
          {loading ? (
            <span className={styles.spinner}></span>
          ) : (
            <b className={styles.createProfile}>Sign Up</b>
          )}
        </button>
      </div>
      <div className={styles.divider}>
        <hr className={styles.dividerLine} />
        <span className={styles.dividerText}>or</span>
        <hr className={styles.dividerLine} />
      </div>
      <div className={styles.googleButtonWrapper}>
        <button
          type="button"
          onClick={handleGoogleSignup}
          disabled={loading}
          className={styles.googleButton}
        >
          <img
            src={googleImg}
            alt="Sign in with Google"
            className={styles.googleLogo}
          />
        </button>
      </div>
    </form>
  );
};

SignUpForm.propTypes = {
  toggleMode: PropTypes.func.isRequired,
};

export default SignUpForm;
