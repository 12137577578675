import PropTypes from "prop-types";
import styles from "./DisplayChatbotProfile.module.css";
import React from "react";

const DisplayChatbotProfile = ({
	className = "",
	name = "Name",
	age = "N/A",
	gender = "N/A",
	profilePictureURL = "",
	school = "",
	major = "",
}) => {

	const profileItem = (itemName, itemValue) => {
		return (
			<div className={styles.profileItem}>
				<h3>{itemName}</h3>
				<p>{itemValue}</p>
			</div>
		);
	}

	return (
		<div className={styles.profileContainer}>
			<div className={styles.profile}>
				{!profilePictureURL && (
					<h2 className={styles.profilePicture}>Profile Picture</h2>
				)}
				{profilePictureURL && (
					<img
						src={profilePictureURL}
						alt="Profile"
						className={styles.profileImage} 
					/>
				)}
			<h2 className={styles.name}>{name}</h2>
			</div>
			<div className={styles.profileInfo}>
				<>{profileItem("Gender", gender)}</>
				<>{profileItem("Age", age)}</>
				<>{profileItem("Major", major)}</>
				<>{profileItem("School", school)}</>
			</div>
		</div>
	);
};

DisplayChatbotProfile.propTypes = {
	className: PropTypes.string,
	name: PropTypes.string,
	age: PropTypes.string, 
	gender: PropTypes.string,
	profilePictureURL: PropTypes.string,
	school: PropTypes.string,
	major: PropTypes.string,
};

export default DisplayChatbotProfile;