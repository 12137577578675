import React, { useState } from "react";
import Footer from "../../common/Footer";
import styles from "./Contact.module.css";
import { db, setDoc, doc } from "../../firebase-config"; 

const Contact = () => {
  const [selectedFirst, setSelectedFirst] = useState(null);
  const [selectedSecond, setSelectedSecond] = useState(null);
  const [nameValue, setNameValue] = useState("");
  const [emailValue, setEmailValue] = useState("");
  const [messageValue, setMessageValue] = useState("");
  
  const handleFirstClick = (button) => {
    setSelectedFirst(button);
  };
  
  const handleSecondClick = (button) => {
    setSelectedSecond(button);
  };
  
  const validateEmail = (email) => {
    // Simple regex for email validation
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(email);
  };
  
  const handleSubmit = async () => {
    let alertMessages = [];
    
    if (!nameValue) {
      alertMessages.push("Name is required.");
    }
    if (!emailValue) {
      alertMessages.push("Email is required.");
    } else if (!validateEmail(emailValue)) {
      alertMessages.push("Must be a valid email address.");
    }
    if (!messageValue) {
      alertMessages.push("Message is required.");
    }
    if (!selectedFirst) {
      alertMessages.push("You must select one of the first options.");
    }
    if (!selectedSecond) {
      alertMessages.push("You must select one of the second options.");
    }

    if (alertMessages.length > 0) {
      alert(alertMessages.join("\n")); // Display all messages in one alert box
    } else {
      try {
        const docId = new Date().getTime().toString();
        await setDoc(doc(db, "Notifications", docId), {
          email: emailValue,
          message: messageValue,
          name: nameValue,
          party: selectedFirst,
          type: selectedSecond,
        });
        alert('Message sent successfully');
        resetForm(); // Reset the form after successful submission
      } catch (error) {
        console.error("Error sending message: ", error);
        alert('Failed to send message: ' + error.message);
      }
    }
  };
  
  const resetForm = () => {
    setSelectedFirst(null);
    setSelectedSecond(null);
    setNameValue("");
    setEmailValue("");
    setMessageValue("");
  };

  return (
    <div className={styles.contact}>
      <header className={styles.header}>
        <h1 className={styles.getInTouch}>get in touch</h1>
        <br></br>
        <h3 className={styles.notifyUs}>Notify us with any questions, comments, or concerns you might have.</h3>
      </header>
      <main className={styles.mainContent}>
        <div className={styles.buttonContainerOne}>
          <button
            className={`${styles.first} ${selectedFirst === "Student" ? styles.selected : ""}`}
            onClick={() => handleFirstClick("Student")}
          >
            Student
          </button>
          <button
            className={`${styles.first} ${selectedFirst === "Startup" ? styles.selected : ""}`}
            onClick={() => handleFirstClick("Startup")}
          >
            Startup
          </button>
        </div>
        <div className={styles.buttonContainerTwo}>
          <button
            className={`${styles.second} ${selectedSecond === "Question" ? styles.selected : ""}`}
            onClick={() => handleSecondClick("Question")}
          >
            Question
          </button>
          <button
            className={`${styles.second} ${selectedSecond === "Comment" ? styles.selected : ""}`}
            onClick={() => handleSecondClick("Comment")}
          >
            Comment
          </button>
          <button
            className={`${styles.second} ${selectedSecond === "Concern" ? styles.selected : ""}`}
            onClick={() => handleSecondClick("Concern")}
          >
            Concern
          </button>
        </div>
        <input
          type="text"
          className={styles.inputField}
          placeholder="Name"
          value={nameValue}
          onChange={(e) => setNameValue(e.target.value)}
        />
        <input
          type="email"
          className={styles.inputField}
          placeholder="Email"
          value={emailValue}
          onChange={(e) => setEmailValue(e.target.value)}
        />
        <textarea
          className={styles.inputMessage}
          placeholder="Write your message here"
          rows="3"
          value={messageValue}
          onChange={(e) => setMessageValue(e.target.value)}
        ></textarea>
        <button className={styles.submit} onClick={handleSubmit}>Submit</button>
      </main>
      <div className={styles.footer}>
        <Footer />
      </div>
    </div>
  );
};

export default Contact;
