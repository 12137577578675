import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import {
	auth,
	signInWithEmailAndPassword,
	sendPasswordResetEmail,
	db,
	updateDoc,
	doc,
	getDoc,
} from "../../../firebase-config";
import { GoogleAuthProvider, signInWithPopup } from "firebase/auth";
import googleImg from "../p1/google.png";
import styles from "./SignUpForm.module.css";

const LoginForm = () => {
	const [email, setEmail] = useState("");
	const [isStudentEmail, setIsStudentEmail] = useState(false);
	const [password, setPassword] = useState("");
	const [loading, setLoading] = useState(false);
	const [error, setError] = useState("");
	const [message, setMessage] = useState("");
	const navigate = useNavigate();

	const handleEmailChange = (e) => {
		const enteredEmail = e.target.value;
		setEmail(enteredEmail);
		setIsStudentEmail(enteredEmail.endsWith(".edu"));
	};

	const handleLogin = async (e) => {
		e.preventDefault();
		setLoading(true);

		// Check if the entered email is a .edu email address
		if (!isStudentEmail) {
			setError("Students must log in using a .edu email address.");
			setLoading(false);
			return;
		}

		try {
			const userCredential = await signInWithEmailAndPassword(
				auth,
				email,
				password
			);
			const user = userCredential.user;

			if (!user.emailVerified) {
				setError("Please verify your email before logging in.");
				setLoading(false);
				return;
			}

			// Update Firestore with the school email after successful login
			await updateDoc(doc(db, "Students", user.uid), {
				schoolEmail: user.email,
			});

			const userDocRef = doc(db, "Students", user.uid);
			const userDoc = await getDoc(userDocRef);

			if (userDoc.exists()) {
				const userData = userDoc.data();
				const profileCreated = userData.profileCreated;

				if (!profileCreated) {
					navigate("/students-p2"); // First-time setup page
				} else {
					navigate("/students-p3"); // Normal student page
				}
			} else {
				setError("User data not found. Please contact support.");
			}
		} catch (error) {
			setError("Error during login. Please try again.");
		} finally {
			setLoading(false);
		}
	};

	const handleForgotPassword = async () => {
		if (!email) {
			setError("Please enter your email to reset your password.");
			return;
		}

		try {
			await sendPasswordResetEmail(auth, email);
			setMessage("Password reset email sent. Please check your inbox.");
		} catch (error) {
			setError("Error sending password reset email. Please try again.");
		}
	};

	const handleGoogleLogin = async () => {
		setLoading(true);
		try {
			const provider = new GoogleAuthProvider();
			const result = await signInWithPopup(auth, provider);
			const user = result.user;

			// Check if the Google account email ends with ".edu"
			if (!user.email.endsWith(".edu")) {
				setError("Students must log in using a .edu email address.");
				await auth.signOut();
				setLoading(false);
				return;
			}

			// Update Firestore with the school email after successful login
			await updateDoc(doc(db, "Students", user.uid), {
				schoolEmail: user.email,
			});

			const userDocRef = doc(db, "Students", user.uid);
			const userDoc = await getDoc(userDocRef);

			if (userDoc.exists()) {
				const userData = userDoc.data();
				const profileCreated = userData.profileCreated;

				if (!profileCreated) {
					navigate("/students-p2"); // First-time setup page
				} else {
					navigate("/students-p3"); // Normal student page
				}
			} else {
				setError("User data not found. Please contact support.");
			}
		} catch (error) {
			setError("Error during Google login. Please try again.");
			console.error("Google Login Error:", error);
		} finally {
			setLoading(false);
		}
	};

	return (
		<form className={styles.signUpForm} onSubmit={handleLogin}>
			<div className={styles.emailWrapper}>
				<input
					type="email"
					placeholder="School Email"
					value={email}
					onChange={handleEmailChange}
					className={`${styles.input} ${!isStudentEmail && email ? styles.invalidInput : ""
						}`}
					required
					disabled={loading}
				/>
			</div>
			<div className={styles.passwordWrapper}>
				<input
					type="password"
					placeholder="Password"
					value={password}
					onChange={(e) => setPassword(e.target.value)}
					className={styles.input}
					required
					disabled={loading}
				/>
			</div>
			<div className={styles.switchTextWrapper}>
				<p className={styles.switchText}>
					Forgot your password?{" "}
					<span className={styles.switchLink} onClick={handleForgotPassword}>
						Reset
					</span>
				</p>
			</div>
			{error && <p className={styles.error}>{error}</p>}
			{message && <p className={styles.message}>{message}</p>}
			<div className={styles.submissionButton}>
				<button
					type="submit"
					className={styles.done}
					disabled={loading || !isStudentEmail}
				>
					{loading ? (
						<span className={styles.spinner}></span>
					) : (
						<b className={styles.createProfile}>Sign In</b>
					)}
				</button>
			</div>
			<div className={styles.divider}>
				<hr className={styles.dividerLine} />
				<span className={styles.dividerText}>or</span>
				<hr className={styles.dividerLine} />
			</div>
			<div className={styles.googleButtonWrapper}>
				<button
					type="button"
					onClick={handleGoogleLogin}
					disabled={loading}
					className={styles.googleButton}
				>
					<img
						src={googleImg}
						alt="Sign in with Google"
						className={styles.googleLogo}
					/>
				</button>
			</div>
		</form>
	);
};

export default LoginForm;
