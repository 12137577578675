import React, { useState, useEffect } from "react";
import styles from "./Dashboard.module.css";
import { db, doc, getDoc, updateDoc } from "../../../firebase-config";
import { auth } from "../../../firebase-config";

const Dashboard = () => {
  const [users, setUsers] = useState([]); 
  const [loading, setLoading] = useState(true); 

  useEffect(() => {
    const fetchAdminStatusAndUsers = async () => {
      try {
        const user = auth.currentUser; 
        if (user) {
          console.log("Fetching user data for:", user.uid);
          const userDocRef = doc(db, 'Students', user.uid);
          const userDocSnap = await getDoc(userDocRef);

          if (userDocSnap.exists()) {
            console.log("User data:", userDocSnap.data());
            const syncedUsersUIDs = userDocSnap.data().syncedUsers || [];
            console.log("Synced UIDs:", syncedUsersUIDs);

            const syncedUsersData = [];
            
            for (const uid of syncedUsersUIDs) {
              const userRef = doc(db, 'Students', uid);
              const userSnap = await getDoc(userRef);
              if (userSnap.exists()) {
                syncedUsersData.push({ uid, ...userSnap.data() });
              }
            }

            setUsers(syncedUsersData); 
          } else {
            console.log("No such document!"); 
          }
        } else {
          console.log("No user logged in"); 
        }
      } catch (error) {
        console.error("Error fetching users:", error); 
      } finally {
        setLoading(false); 
      }
    };

    const unsubscribe = auth.onAuthStateChanged((user) => {
      if (user) {
        fetchAdminStatusAndUsers();
      } else {
        console.log("User not logged in");
        setLoading(false);
      }
    });

    return () => unsubscribe(); 
  }, []);

  const handleUnsync = async (userId) => {
    try {
      const user = auth.currentUser;
      if (user) {
        const userDocRef = doc(db, 'Students', user.uid);
        const userDocSnap = await getDoc(userDocRef);

        if (userDocSnap.exists()) {
          const currentSyncedUsers = userDocSnap.data().syncedUsers || [];
          const updatedSyncedUsers = currentSyncedUsers.filter(uid => uid !== userId);

          await updateDoc(userDocRef, { syncedUsers: updatedSyncedUsers });

          setUsers((prevUsers) => prevUsers.filter((user) => user.uid !== userId));
          console.log(`User with UID ${userId} unsynced successfully.`);
        }
      }
    } catch (error) {
      console.error("Error unsyncing user:", error);
    }
  };

  const handleContact = (email) => {
    alert(`Contact Email: ${email}`);
  };

  if (loading) {
    return <div>Loading...</div>; 
  }

  return (
    <div className={styles.dashboardContainer}>
      {users.length === 0 ? (
        <div>No synced users found.</div>
      ) : (
        <div className={styles.gridContainer}>
          {users.map((user) => (
            <div key={user.uid} className={styles.profileCard}>
              <div className={styles.profileImageContainer}>
                {user.headshot ? (
                  <img src={user.headshot} alt="Profile" className={styles.profileImage} />
                ) : (
                  <div className={styles.noImage}>No Profile Picture</div>
                )}
              </div>
              <div className={styles.profileDetails}>
                <b className={styles.name}>{user.fullName}</b>
                <div className={styles.age}>Age: {user.age}</div>
                <div className={styles.gender}>Gender: {user.gender}</div>
                <div className={styles.school}>{user.school}</div>
                <div className={styles.major}>{user.major}</div>
              </div>
              <div className={styles.buttonContainer}>
                <button
                  className={styles.unsyncButton}
                  onClick={() => handleContact(user.schoolEmail)}
                >
                  Contact
                </button>
                <button
                  className={styles.unsyncButton}
                  onClick={() => handleUnsync(user.uid)}
                >
                  Unsync
                </button>
              </div>
            </div>
          ))}
        </div>
      )}
    </div>
  );
};

export default Dashboard;
