import PropTypes from "prop-types";
import styles from "./DisplayProfile.module.css";
import React from "react";

const DisplayProfile = ({
	className = "",
	name = "Name",
	age = "N/A",
	gender = "N/A",
	profilePictureURL = "",
	school = "",
	major = "",
}) => {

	return (
		<div className={[styles.picture, className].join(" ")}>
			<div className={styles.picture1}>
				{!profilePictureURL && (
					<h2 className={styles.profilePicture}>Profile Picture</h2>
				)}
				{profilePictureURL && (
					<img
						src={profilePictureURL}
						alt="Profile"
						className={styles.profileImage} 
					/>
				)}

			</div>
			<div className={styles.profileDetails}>
				<div className={styles.personalInfo}>
					<div className={styles.namebox} />
					<b className={styles.name}>{name}</b>
				</div>
				<div className={styles.personalInfo1}>
					<div className={styles.userGender}>
						<div className={styles.agebox} />
						<div className={styles.age}>Age: {age}</div>
					</div>
					<div className={styles.userGender1}>
						<div className={styles.genderbox} />
						<div className={styles.gender}>Gender: {gender}</div>{" "}
					</div>
				</div>
				<div className={styles.personalInfo2}>
					<div className={styles.schoolbox} />
					<div className={styles.school}>{school}</div>
				</div>
				<div className={styles.personalInfo2}>
					<div className={styles.schoolbox} />
					<div className={styles.major}>{major}</div>
				</div>
			</div>
		</div>
	);
};

DisplayProfile.propTypes = {
	className: PropTypes.string,
	name: PropTypes.string,
	age: PropTypes.string, 
	gender: PropTypes.string,
	profilePictureURL: PropTypes.string,
	school: PropTypes.string,
	major: PropTypes.string,
};

export default DisplayProfile;